import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAgents(ctx, queryParams){
      var data = JSON.stringify(queryParams);
      //var data = JSON.stringify(queryParams);
      // ชconsole.log('queryParams',data);
      return new Promise((resolve, reject) => {
          
          axios
          .post('/users/v1/lists/4', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
  
    },
    fetchDelete(ctx,id){
      return new Promise((resolve, reject) => {
        axios
          .delete('/users/v1/delete/'+id)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }
  },
}
